import * as Sentry from "@sentry/react";

import { isAxiosError } from "@/react/lib/axios-helpers";

const IGNORED_ERROR_CODES = [400, 401, 403, 404, 409, 422];

export function initSentry() {
  if (window.sentryDsn) {
    Sentry.init({
      dsn: window.sentryDsn,
      environment: window.sentryEnv,
      release: process.env.SENTRY_RELEASE,
      ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "TypeError: a[b].target.className.indexOf is not a function",
        "ReferenceError: 'Promise' is undefined",
        "SecurityError: Blocked a frame with origin",
        "Loading chunk",
        "Route not found: ",
        "The operation is insecure.",
        "is not defined",
        "ResizeObserver loop completed with undelivered notifications.",
        "msDiscoverChatAvailable",
      ],
      beforeSend: (event, hint) => {
        // Filter out axios errors that have a response status code
        if (
          hint?.originalException &&
          isAxiosError(hint.originalException) &&
          !!hint.originalException.response?.status &&
          IGNORED_ERROR_CODES.includes(hint.originalException.response?.status)
        ) {
          return null;
        }

        // Filter out errors from the OneTrust cookie banner.
        // This is code we can't control and it often causes JS errors.
        // But it's not a critical function of the site. The app will continue
        // to operate properly even if the cookie banner breaks.
        if (isOneTrustError(hint)) {
          return null;
        }
        return event;
      },
    });
  }
}

function isOneTrustError(hint: Sentry.EventHint) {
  const checkUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.host === "cdn.cookielaw.org";
    } catch (e) {
      return false;
    }
  };

  if (typeof hint.originalException === "string") {
    return checkUrl(hint.originalException);
  } else {
    return (
      typeof hint.originalException === "object" &&
      hint.originalException !== null &&
      "stack" in hint.originalException &&
      typeof (hint.originalException as { stack: string }).stack === "string" &&
      checkUrl((hint.originalException as { stack: string }).stack)
    );
  }
}
